
export default {
  name: 'Header',

  data() {
    return {
      isActive: false,
      showOverlay: false,
      lastScrollTop: 0,
      scrolled: false,
      delta: 90,
      deltaTablet: 55,
      deltaMobile: 60,
      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
      },
    };
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    disableScroll() {
      document.body.style.overflow = 'hidden';
    },
    enableScroll() {
      document.body.style.removeProperty('overflow');
    },
    enableBodyScroll() {
      this.showOverlay = false;
      document.body.style.removeProperty('overflow');
    },
    bodyScroll() {
      this.showOverlay = !this.showOverlay;
      if (this.showOverlay === true) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
    },
    handleScroll() {
      this.scrolled = window.scrollY;
      if (
        this.scrolled > this.getDelta() ||
        this.scrolled > this.getTopPosition()
      ) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
    getTopPosition() {
      let position;
      if (typeof this.$refs.headerInside !== 'undefined') {
        position =
          this.$refs.headerInside.getBoundingClientRect().top + window.scrollY;
      }
      return position;
    },
    isTablet() {
      return 940;
    },
    isMobile() {
      return 580;
    },
    getDelta() {
      let windowWidth = window.innerWidth;

      if (windowWidth < this.isMobile()) {
        return this.deltaMobile;
      } else if (
        windowWidth < this.isTablet() &&
        windowWidth > this.isMobile()
      ) {
        return this.deltaTablet;
      } else if (windowWidth > this.isTablet()) {
        return this.delta;
      }
    },
    onClickOutside() {
      this.showOverlay = !this.showOverlay;
      this.enableBodyScroll();
    },
    middleware() {
      return true;
    },
    checkRoute(name) {
      if (name === this.$nuxt.$route.name) {
        this.onClickOutside();
      }
    },
  },
};
