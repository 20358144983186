export const state = () => ({
  isVisible: false,
  searchToggle: false,
  categoriesToggle: false
})

export const mutations = {
  show(state) {
    state.isVisible = true
  },
  hide(state) {
    state.isVisible = false
  },
  toggle(state) {
    state.isVisible = !state.isVisible
  },
  closeSearch(state) {
    state.searchToggle = false
  },
  openSearch(state) {
    state.searchToggle = false
  },
  openCategories(state) {
    state.categoriesToggle = true
  },
  closeCategories(state) {
    state.categoriesToggle = false
  },
  toggleCategories(state) {
    state.categoriesToggle = !state.categoriesToggle
  },
  toggleSearch(state) {
    state.searchToggle = !state.searchToggle
  }
}

export const getters = {
  isVisible: (state) => state.isVisible,
  searchToggle: (state) => state.searchToggle,
  categoriesToggle: (state) => state.categoriesToggle
}
