// plugins/bl-components.js

import Vue from 'vue';
import LazyBackgroundWrapper from '~/components/LazyBackgroundWrapper';
import LazyImgWrapper from '~/components/LazyImgWrapper';

const components = {
  LazyBackgroundWrapper,
  LazyImgWrapper,
};

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
