
import LazyBackground from './LazyBackground'

export default {
  name: 'LazyBackgroundWrapper',
  components: { LazyBackground },
  props: {
    backgroundPath: {
      type: String,
      required: true,
      default: '',
    },
    backgroundClass: {
      type: String,
      required: true,
      default: null,
    },
    notLazy: {
      type: Boolean,
      default: false,
    },
  },
}
