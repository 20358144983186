import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  required,
  email,
  alpha,
  alpha_spaces,
  alpha_dash,
  alpha_num,
  min,
} from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: 'This field is required.',
});

extend('alpha_spaces', alpha_spaces);

extend('alpha_dash', alpha_dash);
extend('min', {
  ...min,
  message: 'Your message is too short.',
});

extend('alpha', alpha);

extend('email', {
  ...email,
  message: 'Please make sure that you enter a valid email address.',
});

extend('alpha_num', alpha_num);

extend('alpha_spaces', {
  validate: (value) => {
    const alpha_spaces_dash = new RegExp(
      '^([a-zA-Z0-9\\-]+)(\\s+)([a-zA-Z0-9\\-]+)?$',
    ); // regex to check if the name contain A-Z, a-z, spaces or dash

    return alpha_spaces_dash.test(value); //Test the regex.
  },
});
