

export default {
  name: 'HeroError',
    data() {
    return {
      backgroundTexture: 'hero-error',
    }
  }
}
