
import { fetch } from 'whatwg-fetch';
import Swal from 'sweetalert2';
import spinner from '~/assets/sprite/svg/spinner.svg';

export default {
  name: 'Contact',
  data: () => ({
    sending: false,
    formData: {
      email: '',
      name: '',
      message: '',
      acceptTerms: [],
    },
    spinner: 'url(' + spinner + ')',
    success:
      "Thank you for your message! We'll get back to you as soon as possible.",
    error: 'Your message could not be sent!',
    antispam: 'Anti-SPAM triggered. Please use another method to contact us.',
  }),
  methods: {
    getFormDataSchema() {
      return {
        email: '',
        name: '',
        message: '',
        acceptTerms: [],
      };
    },
    clearFormData() {
      this.formData = Object.assign({}, this.getFormDataSchema());
      ``;
    },
    isSpam() {
      return this.formData.emailCheck || this.formData.nameCheck;
    },
    handleSubmit() {
      this.$refs.validationObserver.validate().then((valid) => {
        if (valid && !this.isSpam()) {
          this.sending = true;
          this.$refs.validationObserver;
          fetch(
            'https://fblmcu58pi.execute-api.eu-central-1.amazonaws.com/default/SendMail',
            {
              method: 'POST',
              body: JSON.stringify({
                email: this.formData.email,
                name: this.formData.name,
                message: this.formData.message,
              }),
            },
          )
            .then((response) => response.json())
            .then((data) => {
              this.clearFormData();

              this.$nextTick(() => {
                if (data.status === 'success') {
                  Swal.fire('', this.success, 'success');
                } else {
                  Swal.fire('', this.error, 'warning');
                  console.log(data.body.message);
                }
                this.$refs.validationObserver.reset();
                this.sending = false;
              });
            });
        }
        if (this.isSpam()) {
          this.clearFormData();

          this.$nextTick(() => {
            Swal.fire('', this.antispam, 'warning');
            this.$refs.validationObserver.reset();
            this.sending = false;
          });
        }
      });
    },
  },
};
