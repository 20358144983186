
import LazyImg from './LazyImg'

export default {
  name: 'LazyImgWrapper',
  components: { LazyImg },
  props: {
    imagePath: {
      type: String,
      required: true,
      default: '',
    },
    imageAlt: {
      type: String,
      required: true,
      default: null,
    },
    sizes: {
      type: String,
      required: true,
      default: null,
    },
    notLazy: {
      type: Boolean,
      default: false,
    },
    imageClass: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'true',
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
  },
}
