import Vue from 'vue';
import VueGtag from 'vue-gtag';

const vueGtag = ({ app }) => {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.GOOGLE_ANALYTICS },
      disableScriptLoad: true,
    },
    app.router,
  );
};

export default vueGtag;
